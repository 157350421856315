import React from 'react'
import Img, { GatsbyImageProps } from 'gatsby-image'
import styled from 'styled-components'
import Form from './Form'
import { Container as FormWrapper } from './Contact'

const Container = styled.div`
  max-width: 100vw;
  overflow: hidden;
  position: relative;
`

interface IBgImageProps {
  height: string
}

const Content = styled<IBgImageProps, any>('section')`
  position: absolute;
  margin: 0 10%;
  z-index: 99;
  height: ${props => props.height || 'auto'};
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;

  @media (max-width: 900px) {
    text-align: center;
    margin: 0;
    width: 100%;
    height: auto;
    margin-top: 80px;

    p,
    button {
      display: none;
    }
  }

  @media (max-width: 500px) {
    align-items: center;
    p,
    button {
      display: block;
    }
  }

  h1,
  h2,
  h3 {
    font-size: 30px;
    margin: 1rem 0;
    color: #fff;
    text-shadow: 0 1px 55px rgba(0, 0, 0, 0.7);
  }
  p {
    color: #fff;
    font-size: 18px;
    max-width: 420px;
    width: auto;
    text-shadow: 0 1px 35px rgba(0, 0, 0, 0.7);
  }
`
const BgImage = styled<IBgImageProps, any>(Img)`
  position: absolute;
  top: 0;
  left: -5%;
  width: 110%;
  z-index: -1;
  height: ${props => props.height || 'auto'};

  @media (max-width: 500px) {
    height: 450px;
  }
`

const FormContainer = styled.div`
  width: 320px;
  background: #fff;
  border-radius: 4px;
  padding: 1.5rem 2rem;
  position: absolute;
  right: 10%;
  top: calc(50% + 30px);
  transform: translateY(-50%);

  @media (max-width: 900px) {
    left: 50%;
    right: auto;
    bottom: 20px;
    top: auto;
    transform: translateX(-50%);
    width: 90%;
    max-width: 340px;
  }

  @media (max-width: 500px) {
    display: none;
  }

  input {
    padding: 0.75rem 1rem;
  }

  h2 {
    color: #333 !important;
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
    font-weight: 400;
    text-align: center;
    text-shadow: none;
  }

  @media (max-width: 900px) {
    margin-top: 2rem;
  }

  @media (max-width: 480px) {
    width: 105%;
  }
`

interface IHeroProps {
  height: string
  bgImage: GatsbyImageProps
  children: React.ReactChild
  noForm: boolean
}

const Hero = ({ height, bgImage, children, noForm }: IHeroProps) => (
  <Container>
    <Content height={height}>{children}</Content>
    {bgImage && <BgImage height={height} fluid={bgImage.fluid} />}
    {!noForm && (
      <FormContainer>
        <Form title="Contact us today!" showMessage={false} />
      </FormContainer>
    )}
  </Container>
)

export default Hero
